import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import ConfirmModal from "@/ui/views/CartCheckout/components/ConfirmModal";

const StepStep = ({ id, title, onClick, step }) => {
  const navigate = useNavigate();
  const stepRef = useRef(null);
  const [active, setActive] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const currentStep = useSelector((state) => state.dataStepz.currentStep);
  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const routes = useSelector((state) => state.dataStepz.routes);
  const setSpetVariableData = useSelector(
    (state) => state.dataSearchEngine.spetVariableData
  );

  const confirmProcedure = () => {
    setShowConfirmModal(false);
    navigate("/build-package/std");
  };

  const changeStep = (step) => {
    if (step === 1 && routes.specialist) {
      navigate(`${routes.specialist.route}${routes.specialist.search}`);

    } else if (step === 2 && routes.clinics) {
      navigate(`${routes.clinics.route}${routes.clinics.search}`);

    }else if (step === 3 && routes.hotels && (setSpetVariableData === 0 || setSpetVariableData === 2)) {
      navigate(`${routes.hotels.route}`);

    }  else if (step === 3 && routes.flights && setSpetVariableData === 1) {
      navigate(`${routes.flights.route}`);

    } else if (step === 4 && routes.flights && setSpetVariableData === 2) {
      navigate(`${routes.flights.route}`);

    } else if (step === 4 && routes.clinics && setSpetVariableData === 2) {
      navigate(`${routes.clinics.route}${routes.clinics.search}`);

    } else if (step === 4 && routes.summary && (setSpetVariableData === 0 || setSpetVariableData === 1)) {
      navigate(`${routes.summary.route}`);

    } else if (step === 5 && routes.summary && setSpetVariableData === 2) {
      navigate(`${routes.summary.route}`);

    } else if ((step === 5 || step === 6) && routes.std) {
      setShowConfirmModal(true);
    }
  };
  useEffect(() => {
    if (currentStep === step) {
      setActive(true);

      if (window.innerWidth < 640 && stepRef.current) {
        stepRef.current.scrollIntoView({ behavior: "smooth", inline: "center" });
      }
    }
  }, [currentStep, step]);

  const isCompleted = currentStep > step;

  return (
    <>
      <div className={styles.itemSectionAll} ref={stepRef}>
        <div className={styles.stepToStep}>
          <div
            className={`${styles.itemSection} ${active ? styles.active : ""} ${isCompleted ? styles.completed : ""
              }`}
            onClick={() => changeStep(step)}
          >
            <h2 className={styles.itemText}>{step}</h2>
            <div className={styles.itemNumberTitle}>
              <h3 className={styles.itemText}>{title}</h3>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        confirmProcedure={confirmProcedure}
        hasCloseButton={false}
      />
    </>
  );
};

export default StepStep;
