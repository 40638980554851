import { setNationalityData } from "@/store/slices/dataEnginePackage/dataEnginePackageSlice";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const InputNacionality = ({
  nacionalityDropdown,
  setNacionalityDropdown,
  nacionality,
  validationForm,
  paceholders,
  codeNacionality = false,
  findCountryClient,
  loading,
  dataNacionality,
  selectedNacionality,
}) => {
  const { t } = useTranslation("global");

  // Variables
  const dispatch = useDispatch();

  const inputRef = useRef(null);
  const isScrolled = false;
  const showSearchScrolled = false;

  const nationalityData = useSelector(
    (state) => state.dataSearchEngine.nationalityData
  );

  // Functions
  const toCamelCaseWithFirstUpper = (str) => {
    const camelCaseStr = str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
    return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
  };

  // UseEffects
  useEffect(() => {
    nacionality = nationalityData;
  }, []);

  useEffect(() => {
    if (nacionalityDropdown) {
      inputRef.current.focus();
    }
  }, [nacionalityDropdown]);

  useEffect(() => {
    dispatch(setNationalityData(paceholders));
  }, [nacionality]);

  return (
    <div
      className="searchBuildPackageContentInput"
      onClick={() => {
        setNacionalityDropdown(!nacionalityDropdown);
      }}
      style={{
        borderColor:
          nacionality === "" && validationForm ? "var(--redPrimary)" : "",
        border: codeNacionality === false && "none",
        padding: codeNacionality === false ? "0" : ""

      }}
    >
      <div className="searchBuildPackageContentInputContent">
        <TextComponent
          children={t("build_package.search_engine.nationality.text")}
          fontWeight={codeNacionality === false ? "bold" : ""}
          size={codeNacionality === false ? "sm" :"md2"}
          tag={"p"}
          color={
            nacionality === "" && validationForm
              ? "var(--redPrimary)"
              : codeNacionality === false ? "var(--bluePrimary)" : "var(--greySecundary)"
          }
        />
        <input
          type="text"
          placeholder={t("build_package.search_engine.nationality.placeholder")}
          ref={inputRef}
          value={
            nacionality === "" ? t("build_package.search_engine.nationality.country") : toCamelCaseWithFirstUpper(nacionality)
          }
          style={{
            fontWeight: nacionality === "" && validationForm ? "400" : "500",
            color:
              nacionality === "" && validationForm
                ? "var(--greySecundary)"
                : "var(--bluePrimary)",
              borderColor : nacionality === "" && validationForm ? "var(--redPrimary)" : ""
          }}
        />
      </div>
      <SvgIcon
        name={iconList.IconBander}
        color={"var(--bluePrimary)"}
        size={24}
      />
      {nacionalityDropdown && (
        <div className="dropdown-nacionality">
          <div className="input-label-nationality" style={{
            padding: codeNacionality === false ? "0" : ""
          }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <TextComponent
                children={t("build_package.search_engine.nationality.text")}
                size={codeNacionality === false ? "sm" :"md2"}
                fontWeight={codeNacionality === false ? "bold" : ""}
                color={
                  nacionality === "" && validationForm
                    ? "var(--redPrimary)"
                    : codeNacionality === false ? "var(--bluePrimary)" : "var(--greySecundary)"
                }
              />

              <input
                ref={inputRef}
                type="text"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontWeight: nacionality === "" && validationForm ? "400" : "500",
                  color: "var(--bluePrimary)",
                }}
                placeholder={
                  nacionality === ""
                    ? t("build_package.search_engine.nationality.placeholder")
                    : `${nacionality}, ${codeNacionality}`
                }
                onChange={(e) => {
                  findCountryClient(e.target.value);
                }}
              />
            </div>
            {loading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "20px",
                }}
                size={25}
              />
            )}
          </div>

          {dataNacionality.length > 0 &&
            !(isScrolled && showSearchScrolled) && (
              <ul>
                {dataNacionality.length >= 1 &&
                  dataNacionality.map((item, key) => {
                    return (
                      <li
                        key={key}
                        onClick={() => {
                          selectedNacionality(item.code, item.name);
                          setNacionalityDropdown(false);
                        }}
                      >
                        {item.name}, {item.code}
                      </li>
                    );
                  })}
              </ul>
            )}
        </div>
      )}
    </div>
  );
};

export default InputNacionality;
