import CarouselSevices from "../share/components/CarouselServices/CarouselSevices";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useTranslation } from "react-i18next";
import banner from "../../assets/pictures/BannerNuevo1.jpg";
import banner2 from "../../assets/pictures/BannerNuevo2.jpg";
import banner3 from "../../assets/pictures/BannerNuevo3.jpg";
import banner4 from "../../assets/pictures/BannerNuevo4.jpg";
import banner5 from "../../assets/pictures/BannerNuevo5.jpg";
import PackageSection from "../home/components/PackagesSection/PackageSection";
import arrow from "./assets/svg/arrowDown2.svg";
import axios from "axios";
import "./assets/styles.css";

import { useState, useEffect } from "react";
import AnyQuestions from "../home/components/anyQuestion";
import SvgIcon, { iconList } from "../share/components/iconSVG";
import { Link, useNavigate } from "react-router-dom";
import CustomSlider from "./DetailPackages/sliderImg/CustomSlider";

const Packages = () => {
  const { t } = useTranslation("global");
  const [inputValue, setInputValue] = useState(""); 
  const [selectValue, setSelectValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataPackage, setDataPackage] = useState([]);
  
  let counter = 0;

  const navigate = useNavigate()

  const photos = [
    {
      id: 1,
      img: banner,
    },
    {
      id: 2,
      img: banner2,
    },
    {
      id: 3,
      img: banner3,
    },
    {
      id: 4,
      img: banner4,
    },
  ];

  const findServices = () => {
    setLoading(true)
    if (counter === 0) {
      axios.get(`${process.env.REACT_APP_URL_API_AIOP}api/packages`, {
        params: {
          "destination_type": selectValue,
          "search": inputValue
        }
      })
        .then(res => {
          counter += 1;
          setDataPackage(res.data.packs);
          setLoading(false);
        })
        .catch(error => {
          counter += 1;
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    findServices();
  }, [])

  const redirectTo = (type) => {
    navigate(type === "colombia" ? "/packagesType?type=colombia" : "/packagesType?type=internacional")
  }

  const handleClick = () => {
    navigate('/TourismHealth');
  };

  return (
    <div>
      <CarouselSevices current={t("carousel_services.packages")} />
      <div className="content-packages-new">
        <div className="content-packages-colum-1-touristi">

          <aside onClick={handleClick} style={{ cursor: 'pointer' }}>
            <img src={banner5} className="img-pack-aside" alt="paquetes" />
            <div className="info">
              <div className="letter"></div>
              <div
                className="cardRegisterNowButtonContain"
                style={{
                  position: "relative",
                  bottom: "0px",
                }}
              >
                <Link
                  to={"/TourismHealth"}
                  style={{
                    backgroundColor: "#00AFE8",
                    fontSize: "24px",
                    fontWeight: "900",
                    borderRadius: "0px",
                    width: "100%",
                  }}
                  className="cardRegisterNowButton"
                >
                  {t("common.see_more.uppercase")}
                </Link>
              </div>
            </div>
          </aside>
        </div>

        <div className="content-packages-colum-2">
          <article>
            <CustomSlider>
              {photos.map((image, index) => {
                return (
                  <img
                    className="section-image"
                    src={image.img}
                    key={index}
                    alt={`${index + 1}`}
                    style={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                );
              })}
            </CustomSlider>
            <section className="content-btn-pack">
              <button onClick={() => redirectTo("internacionales")}>
                <div>
                  <span>{t("packs.tourism.international")}</span>
                </div>
              </button>
              <button onClick={() => redirectTo("colombia")}>
                <div>
                  <span>{t("packs.tourism.colombia")}</span>
                </div>
              </button>
            </section>
            <section className="search-packages-turisticos">
              <h2>{t("packs.tourism.tourist")}</h2>
              <p>
                {t("packs.tourism.travel_wellezy")}
              </p>
              <div className="ctn-inputs-tourist">
                <input
                  type="text"
                  placeholder={t("packs.tourism.specific_destination")}
                  onChange={(event) => setInputValue(event.target.value)}
                />
                <div class="content-select">
                  <select
                    name=""
                    id=""
                    onChange={(event) => setSelectValue(event.target.value)}
                  >
                    <option value="">{t("packs.tourism.types.all")}</option>
                    <option value="international">{t("packs.tourism.types.international")}</option>
                    <option value="national">{t("packs.tourism.types.national")}</option>
                    <option value="regional">{t("packs.tourism.types.regional")}</option>
                  </select>
                  <img src={arrow} alt="arrow" />
                </div>
                <button onClick={findServices}>
                  <SvgIcon
                    color={"white"}
                    size={"23"}
                    name={iconList.IconSearch}
                  />
                  {t("packs.tourism.button_text")}
                </button>
              </div>
            </section>
          </article>
        </div>
      </div>

      <PackageSection
        inputValue={inputValue}
        selectValue={selectValue}
        seeker={false}
        dataPackage={dataPackage}
        loading={loading}
      />
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default Packages;
