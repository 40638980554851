import React, { useEffect, useState } from "react";
import CarouselSevices from "../../../share/components/CarouselServices/CarouselSevices";
import StepStep from "../../components/Step/StepStep";
import Flights from "@/ui/views/flights/flights.view";
import Loading from "@/ui/views/share/components/loading";
import useStep from "@/Hooks/BuildPackage/useStep/UseStep";
import AnyQuestions from "@/ui/views/home/components/anyQuestion";
import StepHome from "../../components/Step/StepHome";
import useFlightBuildPackage from "@/Hooks/BuildPackage/useFlightBuildPackage/useFlightBuildPackage";
import DataSearch from "../../components/DataSearch/DataSearch";

import { setCurrentStep, setRoutes } from "@/store/slices/dataStepz/dataStepzSlice";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const StepFlight = () => {
  const dispatch = useDispatch();
  const { t  } = useTranslation("global");
  const location = useLocation();

  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);
  const [dataStepzTitles, setDataStepzTitles] = useState([]);

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const setSpetVariableData = useSelector(
    (state) => state.dataSearchEngine.spetVariableData
  );
  const routes = useSelector((state) => state.dataStepz.routes);

  const { loading } = useStep();
  const {
    activeSessionModal,
    setActiveSessionModal,
  } = useFlightBuildPackage();

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  useEffect(() => {
    if(setSpetVariableData === 1){
      dispatch(setCurrentStep(3));
    }else{
      dispatch(setCurrentStep(4));
    }
    

    dispatch(
      setRoutes({
        ...routes,
        flights: {
          route: location.pathname,
          search: location.search,
        },
      })
    );
  }, []);

  useEffect(() => {
    function handleSetDataStepzTitles() {
        let updatedTitles = [];
        if (setSpetVariableData === 0) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.hotel"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
            ];
        } else if (setSpetVariableData === 1) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
            ];
        } else if (setSpetVariableData === 2) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.hotel"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
            ];
        } else if (setSpetVariableData === 3) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.hotel"),
                "Traslado",
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
            ];
        }
        setDataStepzTitles(updatedTitles);
    }

    handleSetDataStepzTitles();
  }, [setSpetVariableData, t]);

  return (
    <div>
      {loading && <Loading size={100} />}
      <section className="homeSectionCarrousel">
        <CarouselSevices />
      </section>

      <div className="maxWidthBuildPackage">
        <div className="itemSectionAll">
          <StepHome />
          {dataStepzTitles.map((item, index) => (
            <StepStep
              key={index}
              title={item}
              step={index + 1}
              onClick={handleStepClick}
              dataPackage={dataPackage}
            />
          ))}
        </div>

        <DataSearch setSpetVariableData={setSpetVariableData} />

        <Flights />
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default StepFlight;
