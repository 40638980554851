import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  setCurrentStep,
  setRoutes,
} from "@/store/slices/dataStepz/dataStepzSlice";
import useHeaderSession from "@/ui/viewModels/session/header.session.viewModel";

import styles from "./styles.module.css";

import Hotel from "./components/hotel";
import Flights from "./components/flights";
import SvgIcon, { iconList } from "../share/components/iconSVG";
import StepHome from "../BuildPackage/components/Step/StepHome";
import StepStep from "../BuildPackage/components/Step/StepStep";
import DataSearch from "../BuildPackage/components/DataSearch/DataSearch";
import AnyQuestions from "../home/components/anyQuestion";
import ConfirmModal from "./components/ConfirmModal";
import SessionModal from "../session/components/SessionModal/SessionModal";
import ModalContainer from "../share/components/modalContainer";
import CarouselSevices from "../share/components/CarouselServices/CarouselSevices";
import SpecialistClinic from "./components/specialist-clinic";


const CartCheckout = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate()

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  const { userDetail } = useHeaderSession();

  const dispatch = useDispatch();
  const location = useLocation();

  const dataPackage = useSelector((state) => state.dataStepz.dataPackage);
  const valueFlight = dataPackage.flight ? dataPackage.flight.priceTotal : 0;
  const valueHotel = dataPackage.hotel ? dataPackage.hotel.price : 0;
  const totalPrice = valueFlight + valueHotel;

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [modalQuestion, setModalQuestion] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [activeSessionModal, setActiveSessionModal] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsConfirmed(event.target.checked);
  };

  useEffect(() => {
    setActiveSessionModal(false)
  }, [userDetail])

  const confirmProcedure = () => {
    setShowConfirmModal(false);
    navigate("/build-package/std");
  };

  const setSpetVariableData = useSelector(
    (state) => state.dataSearchEngine.spetVariableData
  );

  const routes = useSelector((state) => state.dataStepz.routes);

  const [dataStepzTitles, setDataStepzTitles] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const handleStepClick = (step) => {
    if (step <= activeStep + 1) {
      setActiveStep(step);
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
  };

  useEffect(() => {
    if (setSpetVariableData === 2) {
      dispatch(setCurrentStep(5));
    } else {
      dispatch(setCurrentStep(4));
    }
    dispatch(
      setRoutes({
        ...routes,
        summary: {
          route: location.pathname,
          search: location.search,
        },
      })
    );
  }, []);

  useEffect(() => {
    function handleSetDataStepzTitles() {
        let updatedTitles = [];
        if (setSpetVariableData === 0) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.hotel"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
            ];
        } else if (setSpetVariableData === 1) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
            ];
        } else if (setSpetVariableData === 2) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.hotel"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
            ];
        } else if (setSpetVariableData === 3) {
            updatedTitles = [
                t("build_package.steps.titles.specialist"),
                t("build_package.steps.titles.clinic"),
                t("build_package.steps.titles.flight"),
                t("build_package.steps.titles.hotel"),
                "Traslado",
                t("build_package.steps.titles.resume"),
                t("build_package.steps.titles.tds"),
            ];
        }
        setDataStepzTitles(updatedTitles);
    }

    handleSetDataStepzTitles();
  }, [setSpetVariableData, t]);

  return (
    <>
      <div className={styles.contentCartCheckoutInfo}>
        <section className="homeSectionCarrousel">
          <CarouselSevices />
        </section>

        <SessionModal
          activeSessionModal={activeSessionModal}
          setActiveSessionModal={setActiveSessionModal}
          url={"/build-package/std"}
        />

        <div className={styles.cartCheckoutSection}>
          <div className={styles.itemSectionAll}>
            <StepHome />
            {dataStepzTitles.map((item, index) => (
              <StepStep
                key={index}
                title={item}
                step={index + 1}
                onClick={handleStepClick}
                dataPackage={dataPackage}
              />
            ))}
          </div>
          <div className={styles.containerSearch}>
            <DataSearch />
          </div>
          <div className={styles.cartCheckout}>
            <div className={styles.cart}>
              <h2>{t("build_package.steps.resume.title")}</h2>
              <div className={styles.cartItems}>
                <SpecialistClinic title={true} appointments={dataPackage} />

                {dataPackage.flight && (
                  <Flights title={true} flights={dataPackage.flight} />
                )}

                {dataPackage.hotel && (
                  <Hotel title={true} hotels={dataPackage.hotel} />
                )}
              </div>
            </div>
            <div className={styles.checkout}>
              <div className={styles.checkoutContent}>
                <h4 className={styles.checkoutTitle}>
                  {t("build_package.steps.resume.quote_information")}
                </h4>

                  <ul className={styles.checkoutList}>
                    <li className={styles.checkoutListItem}>
                      {t("build_package.steps.resume.procedure")}
                      <p className={styles.checkoutListPriceTicket}>
                        {t("build_package.steps.resume.to_evaluation")}
                      </p>
                    </li>

                    {dataPackage.flight && (
                      <li className={styles.checkoutListItem}>
                        {t("build_package.steps.resume.flight")}
                        <p className={styles.checkoutListPrice}>
                          {formatPriceCOP(dataPackage.flight.priceTotal)}
                        </p>
                      </li>
                    )}

                    {dataPackage.hotel && (
                      <li className={styles.checkoutListItem}>
                        {t("build_package.steps.resume.hotel")}
                        <p className={styles.checkoutListPrice}>
                          {formatPriceCOP(dataPackage.hotel.price)}
                        </p>
                      </li>
                    )}

                    <li className={styles.checkoutListItem}>
                    {t("build_package.steps.resume.total")}
                      <p className={styles.checkoutListPrice}>
                        {formatPriceCOP(totalPrice)}
                      </p>
                    </li>
                  </ul>
              

                <div className={styles.checkoutActions}>
                  <div className={styles.checkoutCheckboxWrapper}>
                    <input
                      type="checkbox"
                      id="confirm"
                      checked={isConfirmed}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className={styles.checkoutCheckboxLabel}
                      htmlFor="confirm"
                    >
                      {t("build_package.steps.resume.confirm_quote")}
                    </label>
                  </div>

                  <button
                    className={`${styles.checkoutButton} ${!isConfirmed ? styles.checkoutButtonDisabled : ""
                      }`}
                    disabled={!isConfirmed}

                    onClick={() => setShowConfirmModal(true)}
                  >
                    {t("build_package.steps.resume.continue")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AnyQuestions title={t("browse_home.have_doubt")} />
      </div>

      <ConfirmModal
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        confirmProcedure={confirmProcedure}
        hasCloseButton={false}
      />
    </>
  );
};

export default CartCheckout;