import React, { useState } from "react";
import styles from "./styles.module.css";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdFlight, MdLuggage } from "react-icons/md";
import { BsFillLuggageFill } from "react-icons/bs";
import { FaLuggageCart, FaTrashAlt } from "react-icons/fa";
import shoppingCart from "./carts";
import { FaCar, FaHotel } from "react-icons/fa6";
import { RiHotelFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";
import { useDispatch, useSelector } from "react-redux";
import { setDataCart } from "@/store/slices/dataCartBuy/dataCartBuySlice"; 
import ImageJetSmart from "./jetsmart.svg"
import { FaBriefcaseMedical } from "react-icons/fa";
import { MdOutlineTravelExplore } from "react-icons/md";

function textCutter(cantidadCaracteres, texto) {
  if (texto?.length <= cantidadCaracteres) {
    return texto;
  }
  return texto?.slice(0, cantidadCaracteres) + "...";
}

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

const formatDateTime = (dateTimeString) => {
  const [year, month, day, hour, minute] = dateTimeString?.split("-");
  return `${month}/${day}/${year} ${hour}:${minute}`;
};

const MyCart = () => {
  const dispatch = useDispatch()
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Puedes ajustar esto según tus necesidades
  const dataCart = useSelector((state) => state.dataCartBuy.dataCart); // data del carrito redux
  // dispatch(setDataCart([...dataCart, stateProps])); editar el array del carrito en redux

  const USD_TO_COP = 4500;

  const deleteItem = (id)=>{
    const resultArray = dataCart.filter((item)=> item.id !== id)
    dispatch(setDataCart(resultArray));
  }

  // Función para manejar el cambio en la selección del checkbox
  const handleCheckboxChange = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  // Función para eliminar un artículo específico
  const handleRemoveItem = (itemToRemove) => {
    setSelectedItems(selectedItems.filter((item) => item !== itemToRemove));
  };

  // Función para eliminar todos los artículos seleccionados
  const handleRemoveAll = () => {
    setSelectedItems([]);
  };

  const calculateTotal = () => {
    const total = processCartItems(dataCart).reduce((total, item) => total + item.price, 0);
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(total)} COP`;
  }; 

  // Funciones de manejo de paginado
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;  

  const processCartItems = (dataCart) => {
    return dataCart.map((item) => {
      if (item.type === "Hotel") {
        return {
          type: item.type,
          title: `Reserva en ${item.hotel}`,
          description: `Hotel ubicado en ${item?.dataHab?.prn}`,
          longDescription: item?.dataHab?.desc,
          date: item.arrival,
          price: item.price,
          image: item?.dataHab?.foto,
          viewDetailsButton: "Ver detalles",
          url: item?.url,
          id: item?.id
        };
      } else if (item.type === "Vuelo") {
        return {
          type: item.type,
          title: `Vuelo a ${item.itinerary[0].to}`,
          description: item.itinerary[0].scale && item?.itinerary[0].scale.length > 0 ? `Vuelo con escalas a ${item?.itinerary[0].to}` : `Vuelo directo a ${item?.itinerary[0].to}`,
          longDescription: `Viaja a ${item?.itinerary[0]?.to} desde ${item?.itinerary[0]?.from}.`,
          date: formatDate(item?.itinerary[0].dateDeparture),
          price: item?.priceTotal,
          image: item.itinerary[0].company === 'JA' ? ImageJetSmart : `https://pics.avs.io/60/60/${item.itinerary[0].company}.png`,
          viewDetailsButton: "Ver detalles",
          url: item?.url,
          id: item?.id
        };
      } else if (item?.type === "Transport") {
        return {
          type: item?.type,
          title: `Viaja en ${item?.service.vehicle}`,
          description: item?.description,
          longDescription: `Muevete en ${item?.service?.vehicle} con capacidad de ${item?.service?.passengerCapacity} personas.`,
          date: formatDateTime(item?.dateTime),
          price: item?.price * USD_TO_COP,
          image: item?.service?.vehicleHDImage,
          viewDetailsButton: "Ver detalles",
          url: item?.url,
          id: item?.id
        };
      } else if (item?.type === "PackTurist") {
        const imageUrl = `${process.env.REACT_APP_URL_API_AIOP}storage/pack/${item?.pack?.pack?.image}`;
        return {
          type: item?.type,
          title: item?.pack?.pack?.name,
          description: item?.description,
          longDescription: item?.pack?.pack?.description, 
          price: 200000,
          image: imageUrl,
          viewDetailsButton: "Ver detalles",
          url: item?.url,
          id: item?.id
        };
      } else if (item?.type === "PackMedic") {
        const imageUrl = `${process.env.REACT_APP_URL_API_AIOP}storage/medic_pack/${item?.pack?.image}`;
        return {
          type: item?.type,
          title: item?.pack?.name,
          description: item?.description,
          longDescription: `Adquiere la reserva del ${item?.pack?.name}.`, 
          price: 200000,
          image: imageUrl,
          viewDetailsButton: "Ver detalles",
          url: item?.url,
          id: item?.id
        };
      }
      return item;
    });
  };


  const currentItems = processCartItems(dataCart).slice(indexOfFirstItem, indexOfLastItem);

  const currentItemsPrueba = shoppingCart.slice(indexOfFirstItem, indexOfLastItem);
  const nextHandler = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(shoppingCart.length / itemsPerPage))
    );
  };

  const prevHandler = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  const renderIcon = (type) => {
    switch (type) {
      case "Vuelo":
        return <MdFlight />;
      case "Hotel":
        return <RiHotelFill />;
      case "Transport":
        return <FaCar />;
        case "PackTurist":
          return <MdOutlineTravelExplore />;
          case "PackMedic":
            return <FaBriefcaseMedical />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.cartSection}>
      <div className={styles.cart}>
        <h3>Carrito de compras</h3>

        <div className={styles.cartItems}>
          {currentItems?.length > 0 ? (
            currentItems.map((item, index) => (
              <div key={index} className={styles.itemCard}>
                <div className={styles.itemHeader}>
                  <div className={styles.itemHeaderIconText}>
                    <div className={styles.itemHeaderIconTitle}>
                      <div className={styles.icon}>
                        {renderIcon(item.type)}
                      </div>
                      <div className={styles.itemHeaderText}>
                        <h4 className={styles.title}>{item.title}</h4>
                        <p className={styles.shortDescription}>
                          {item.description}
                        </p>
                      </div>
                    </div>
                    <div className={styles.itemImage}>
                      <img src={item.image} alt={item.type} />
                    </div>
                  </div>

                  <p className={styles.itemDate}>{item.date}</p>
                </div>
                <div className={styles.itemDetails}>
                  <p className={styles.longDescription}>
                    {textCutter(230, item?.longDescription)}
                  </p>
                </div>

                <Link to={item.url} className={styles.detailsButton}>
                  {item.viewDetailsButton}
                </Link>
                <div className={styles.priceButton}>
                  <div className={styles.price}>
                    {formatPriceCOP(item.price)}
                  </div>
                  <div className={styles.checkPayButton}>
                    <div className={styles.deleteItem} onClick={()=>deleteItem(item.id)}>
                      <FaTrashAlt />
                    </div>
                    <Link to={item.url} className={styles.payButton}>
                      Ir a pagar
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noItems}>
              <div className={styles.message}>
                El carrito está vacío
                <AiOutlineExclamationCircle
                  size={20}
                  color="var(--bluePrimary)"
                />
              </div>
            </div>
          )}
        </div>

        {dataCart.length > 0 && (
          <Paginado
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={dataCart}
            itemsPerPage={itemsPerPage}
            pageRangeDisplayed={5}
          />
        )}
      </div>

      <div className={styles.total}>
        <h3>Precio total:</h3>
        <h4 className={styles.totalValue}>{calculateTotal()}</h4>
      </div>
    </div>
  );
};

export default MyCart;
