import React, { useEffect, useRef, useState } from "react";
import SvgIcon, { iconList } from "../../share/components/iconSVG";

import CarouselSevices from "../../share/components/CarouselServices/CarouselSevices";
import styled from "styled-components";
import BestExperiences from "../BestExperiences/BestExperiences";
import AnyQuestions from "../../home/components/anyQuestion";

import "./styles.css";

import { motion } from "framer-motion";
import { Dialog, DialogContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as IconCalendar } from "./asset/svg/IconCalendar.svg";
import { ReactComponent as IconPlane } from "./asset/svg/IconPlane.svg";
import { ReactComponent as IconStructure } from "./asset/svg/IconStructure.svg";
import { ReactComponent as IconCar } from "./asset/svg/IconCar.svg";
import axios from "axios";
import ModalContainer from "../../share/components/modalContainer";
import FormPayHealth from "./formPay/FormPayHealth";
import ReturnPage from "../../share/components/return/ReturnPage";
import DisabledModal from "../../share/components/disabledModal/disabledModal";
import Loading from "../../share/components/loading";
import { BsPerson } from "react-icons/bs";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { LuCalendarCheck2 } from "react-icons/lu";
import { GiSchoolBag } from "react-icons/gi";
import { GrLocation } from "react-icons/gr";
import { FaProcedures } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import ReservationForm from "./ReservationForm";
import SessionModal from "@/ui/views/session/components/SessionModal/SessionModal";
import toast, { Toaster } from "react-hot-toast";
import { uuid } from "@/ui/utils";
import { useDispatch, useSelector } from "react-redux";
import { setDataCart } from "@/store/slices/dataCartBuy/dataCartBuySlice";
import { MdOutlineSimCard } from "react-icons/md";
import { FaLink } from "react-icons/fa6";

const DetailPackhealth = () => {
  const notify = (message, icon) => {
    if (typeof message !== "string") {
      console.error("Invalid message type for toast:", message);
      message = "Error desconocido";
    }

    toast(message, {
      icon: icon,
    });
  };
  const dispatch = useDispatch()
  const dataCart = useSelector((state) => state.dataCartBuy.dataCart);
  const constraintsRef = useRef(null);
  const MotionConstraints = styled(motion.div)`
    overflow-x: hidden;
  `;
  const MotionBox = styled(motion.div)`
    width: 100vw;
  `;
  const { t, i18n } = useTranslation("global");

  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedButtonTravel, setSelectedButtonTravel] = useState(null);
  const [packageMedic, setPackageMedic] = useState();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showModalPay, setShowModalPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [specialist, setSpecialist] = useState([])
  const [procedure, setProcedure] = useState("")
  const [doctor, setDoctor] = useState(""); 

  const location = useLocation();
  const data = location.state ? location.state.data : null;

  const navigate = useNavigate();

  const dataString = decodeURIComponent(location.pathname.split("/")[3]);

  // Fetch package medic data on mount
  useEffect(() => {
    if (dataString) {
      getPackageMedic();
    }
  }, [dataString]);

  // Fetch specialists data when packageMedic changes and has a valid speciality_id
  useEffect(() => {
    if (packageMedic?.speciality_id) {
      getSpecialist();
    }
  }, [packageMedic]);

  useEffect(() => {
    if (packageMedic?.specialist_slug) {
      getDoctor()
    }
  }, [packageMedic])

  // Call findItemById when specialists are loaded and packageMedic has a procedure_id
  useEffect(() => {
    if (specialist.length > 0 && packageMedic?.procedure_id) {
      findItemById(packageMedic.procedure_id);
    }
  }, [specialist, packageMedic]);

  const getPackageMedic = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_API_AIOP}api/medical/${dataString}`);
      setPackageMedic(response.data);
    } catch (error) {
      console.error('Error fetching medic package:', error);
    }
  };

  const getSpecialist = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_API_FINANMED}/category/sub/${packageMedic.speciality_id}`);
      setSpecialist(response.data);
    } catch (error) {
      console.error('Error fetching specialists:', error);
    }
  };

  const getDoctor = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_API_FINANMED}/v2/get/doctor/${packageMedic.specialist_slug}`);
      setDoctor(response.data.name || null);

    } catch (error) {
      console.error('Error fetching doctors:', error);
    }
  };

  const findItemById = (id) => {
    if (!id) {
      console.error('Procedure ID is not defined.');
      return;
    }

    const item = specialist.find((element) => element.id === id);
    if (item) {
      setProcedure(item.name);
    } else {
      console.error('Item not found with ID:', id);
    }
  };


  const goReservation = () => {
    navigate(`/TourismHealth/reservation/${packageMedic?.name}`, {
      state: {
        name: packageMedic?.name,
        categories: packageMedic?.supplier,
        urlImage: packageMedic?.image,
        stars: null,
        id: packageMedic?.id,
      },
    });
  };

  const allIcons = [
    {
      icon: <IoLogoWhatsapp color="var(--blueLight)" size={50} />,
      content: "Compartir en WhatsApp",

      onClick: () => {
        const message = i18n.language === "es" ? "Hola%20escribo%20desde%20la%20pagina%20de%20Wellezy%20porque%20quiero%20conocer%20mas%20sobre%20los%20paquetes%20medicos.%0A%20Agradezco%20toda%20la%20información%20que%20me%20puedas%20ofrecer%20de%20turismo%20en%20salud" : 
        "Hello,%20I%20write%20from%20the%20Wellezy%20page%20because%20I%20want%20to%20know%20more%20about%20the%20medical%20packages.%0AThank%20you%20for%20all%20the%20information%20you%20can%20provide%20me%20of%20health%20tourism";
        window.open(`https://api.whatsapp.com/send?phone=573004040116&text=${message}`, "_blank");
      },
    },
    {
      icon: (
        <SvgIcon color="var(--blueLight)" name={iconList.IconEmail} size={50} />
      ),
      onClick: () => {
        window.open("https://mail.google.com/mail/?view=cm&to=reservas@wellezy.com", "_blank");
      },
      content: "reservas@wellezy.com",
    },
    {
      icon: <FaLink color="var(--blueLight)" size={50} />,
      content: "Copiar enlace",

      onClick: () => {
        const currentUrl = new URL(window.location.href);
        if (currentUrl.hostname === 'localhost') {
          currentUrl.hostname = 'wellezy.com';
          currentUrl.protocol = 'https:';
          currentUrl.href = currentUrl.href.replace(":3000", "")
        }
        navigator.clipboard.writeText(currentUrl.href)
      },
    },
    {
      icon: (
        <SvgIcon
          color={"var(--blueLight)"}
          name={iconList.IconXTwitter}
          size={50}
        />
      ),
      content: "",
    },
  ];

  let allButtons = [];

  if (packageMedic?.advantages?.trim() !== "") {
    allButtons.push({ id: 1, text: t("packs.health.detailed_pack.tabs.advantages") });
  }

  if (packageMedic?.description?.trim() !== "") {
    allButtons.push({ id: 2, text: t("packs.health.detailed_pack.tabs.description") });
  }

  if (packageMedic?.inclusions?.trim() !== "") {
    allButtons.push({ id: 3, text: t("packs.health.detailed_pack.tabs.inclusions") });
  }

  if (packageMedic?.exclusions?.trim() !== "") {
    allButtons.push({ id: 4, text: t("packs.health.detailed_pack.tabs.exclusions") });
  }

  if (packageMedic?.why_operate?.trim() !== "") {
    allButtons.push({ id: 5, text: t("packs.health.detailed_pack.tabs.why_choose") });
  }

  if (packageMedic?.extract?.trim() !== "") {
    allButtons.push({ id: 6, text: t("packs.health.detailed_pack.tabs.resume") });
  }

  if (packageMedic?.observations && packageMedic.observations.trim() !== "") {
    allButtons.push({ id: 7, text: t("packs.health.detailed_pack.tabs.observations") });
  }

  const allButtonsSections = [
    packageMedic?.flight_reserves?.length > 0 && {
      id: 1,
      text: t("packs.health.detailed_pack.tabs.flights"),
    },
    packageMedic?.hotel_reserves?.length > 0 && {
      id: 2,
      text: t("packs.health.detailed_pack.tabs.hotels"),
    },
    packageMedic?.traslade_reserves?.length > 0 && {
      // Cambia 'transfer_reserves' a 'traslade_reserves'
      id: 3,
      text: t("packs.health.detailed_pack.tabs.transfers"),
    },
    packageMedic?.tour_reserves?.length > 0 && {
      id: 4,
      text: t("packs.health.detailed_pack.tabs.tours"),
    },
    packageMedic?.assistance_reserves?.length > 0 && {
      // Cambia 'medical_assistances' a 'assistance_reserves'
      id: 5,
      text: t("packs.health.detailed_pack.tabs.medical_assistance"),
    },
  ].filter(Boolean);

  const firstVisibleButton = allButtons[0];

  useEffect(() => {
    if (selectedButton === null || selectedButton === 1) {
      setSelectedButton(firstVisibleButton?.id);
    }
  }, [firstVisibleButton, selectedButton]);

  const firstVisibleButtonSection = allButtonsSections?.find((item) => item);

  useEffect(() => {
    if (selectedButtonTravel == null) {
      setSelectedButtonTravel(firstVisibleButtonSection?.id);
    }
  }, [firstVisibleButtonSection, selectedButtonTravel]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [openReservations, setOpenReservations] = useState(false);

  const handleClickOpenReservations = async () => {
    try {
      const authUserSession = await JSON.parse(localStorage.getItem("@authUserSession") || "{}");

      if (!authUserSession?.user?.id) {
        setShowAuthModal(true);
        return;
      }

      setOpenReservations(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseReservations = () => {
    setOpenReservations(false);
  };

  const addCart = () => {
    dispatch(setDataCart([...dataCart, {
      pack: packageMedic,
      type: "PackMedic",
      url: location.pathname,
      id: uuid(packageMedic?.name)
    }]));

    const message = t("packs.health.detailed_pack.success.added_to_cart");
    notify(message, "✅");
  }

  const serviceTypeMap = {
    "origin_destination": t("packs.health.detailed_pack.transfer_types.origin_destination"),
    "from_the_airport": t("packs.health.detailed_pack.transfer_types.from_the_airport"),
    "to_the_airport": t("packs.health.detailed_pack.transfer_types.to_the_aiport"),
    "inter_hotel": t("packs.health.detailed_pack.transfer_types.inter_hotel")
  };


  return (
    <div>
      <CarouselSevices  current={t("carousel_services.packages")}  /> 
      <ReturnPage />
      <SessionModal
        activeSessionModal={showAuthModal}
        setActiveSessionModal={setShowAuthModal}
        url={`/Tourismhealth`}
      />

      <section className="content-detail-pack-health">
        <section className="layout-result-filter">
          <div className="all-result-search-tourism" style={{ width: "70%" }}>
            <div className="content-info-detail-health">
              <section className="first-section-detail-health">
                <img
                  src={`${process.env.REACT_APP_URL_API_AIOP}storage/medic_pack/${packageMedic?.image}`}
                  alt=""
                  onClick={handleClickOpen}
                  style={{ cursor: 'pointer' }}
                />
                <div>
                  <h2 className="titleDetailHealth">
                    {packageMedic?.name}
                  </h2>
                  <h3 className="titleDetailHealth">
                    {t("packs.health.detailed_pack.for_only")} {packageMedic?.price.toLocaleString("de-DE")}{" "}
                    {packageMedic?.currency}
                  </h3>
                </div>
              </section>
              <Dialog
                PaperProps={{
                  sx: {
                    width: "100%",
                    maxWidth: "530px!important",
                    borderRadius: '12px',
                    maxHeight: '80vh'
                  }
                }}
                BackdropProps={{
                  sx: {
                    backgroundColor: '#021624b0'
                  }
                }}
                open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogContent style={{ padding: 0, position: 'relative' }}>
                  <IoClose
                    onClick={handleClose}
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      cursor: 'pointer',
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: '5px',
                    }}
                    size={24}
                  />
                  <img
                    src={`${process.env.REACT_APP_URL_API_AIOP}storage/medic_pack/${packageMedic?.image}`}
                    alt=""
                    style={{ maxWidth: '100%', minHeight: '250px', backgroundSize: 'cover', backgroundPosition: 'center', objectFit: 'cover' }}
                  />
                </DialogContent>
              </Dialog>
              <section className="second-section-detail-health">
                <div className="second-section-detail-health-items">
                  <ul className="second-section-detail-health-item">
                    <li className="data-list">
                      <FaProcedures
                        color="var(--blueLight)"
                        name={iconList.IconBariatica}
                        size={35}
                      />
                      <p>
                        {t("packs.health.detailed_pack.age_recommendation")}: {packageMedic?.suitable_ages}
                      </p>
                    </li>
                    <li className="data-list">
                      <FaProcedures
                        color="var(--blueLight)"
                        name={iconList.IconBariatica}
                        size={35}
                      />
                      <p>{t("packs.health.detailed_pack.procedure")}: {procedure || 'No procedure found'}</p>
                    </li>
                    <li className="data-list">
                      <GrLocation
                        color="var(--blueLight)"
                        name={iconList.IconPinMap2}
                        size={35}
                      />
                      <p>
                       {t("packs.health.detailed_pack.place_departure")}:{" "}
                        {packageMedic?.departure_place === "medellin update" ||
                          packageMedic?.departure_place == "Medellin update"
                          ? "Medellín"
                          : packageMedic?.departure_place}
                      </p>
                    </li>

                    {doctor && packageMedic?.display_specialist === 1 && (
                      <li className="data-list">
                        <BsPerson size={35} color="var(--blueLight)" />
                        <p>{t("packs.health.detailed_pack.doctor")}: {doctor}</p>
                      </li>
                    )}
                    <li className="data-list">
                      <GrLocation
                        color="var(--blueLight)"
                        name={iconList.IconPinMap2}
                        size={35}
                      />
                      <p>
                        {t("packs.health.detailed_pack.city")}: {packageMedic?.destination}
                      </p>
                    </li>
                    <li className="data-list">
                      <BsPerson size={35} color="var(--blueLight)" />
                      <p>{t("packs.health.detailed_pack.agency")}: {packageMedic?.admin?.name}</p>
                    </li>
                  </ul>
                  <ul className="second-section-detail-health-item">
                    <li className="data-list">
                      <RiMoneyDollarCircleLine
                        size={35}
                        color="var(--blueLight)"
                      />
                      <p>
                        {t("packs.health.detailed_pack.price_from")}:{" "}
                        {packageMedic?.price.toLocaleString("de-DE")}{" "}
                        {packageMedic?.currency}
                      </p>
                    </li>
                    <li className="data-list">
                      <LuCalendarCheck2 size={35} color="var(--blueLight)" />
                      <p>
                        {t("packs.health.detailed_pack.from")}: {packageMedic?.trip_from} 
                        {t("packs.health.detailed_pack.to")} {" "}
                        {packageMedic?.trip_up}
                      </p>
                    </li>
                    <li className="data-list">
                      <LuCalendarCheck2 size={35} color="var(--blueLight)" />
                      <p>{t("packs.health.detailed_pack.package_duration")}: {packageMedic?.day} {t("packs.health.detailed_pack.days")}</p>
                    </li>
                    <li className="data-list">
                      <LuCalendarCheck2 size={35} color="var(--blueLight)" />
                      <p>{t("packs.health.detailed_pack.package_expiration")}: {packageMedic?.date_validity}</p>
                    </li>
                    <li className="data-list">
                      <GiSchoolBag size={35} color="var(--blueLight)" />
                      <p>
                        {t("packs.health.detailed_pack.available_units")}: {packageMedic?.availables_units}
                      </p>
                    </li>

                    {packageMedic?.include_simcard === 1 && (
                      <li className="data-list">
                        <MdOutlineSimCard size={35} color="var(--blueLight)" />
                        <p>
                          {t("packs.health.detailed_pack.include_simcard")}: {t("packs.health.detailed_pack.yes")}
                        </p>
                      </li>
                    )}

                  </ul>
                </div>
              </section>
              <div
                style={
                  packageMedic?.gallery_package_medics && {
                    paddingInline: "2rem",
                  }
                }
              >
                <div style={{ marginBlock: "30px" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "900",
                      color: "var(--blueLight)",
                    }}
                  >
                    {packageMedic?.gallery_package_medics?.length > 0 &&
                      "Galeria"}
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      width: "100%",
                      gap: "15px",
                    }}
                  >
                    {packageMedic?.gallery_package_medics?.map(
                      (item, index) => (
                        <div style={{ width: "100%" }}>
                          <img
                            src={`${process.env.REACT_APP_URL_API_AIOP}storage/${item?.path}`}
                            alt=""
                            style={{
                              width: "100%",
                              height: "150px",
                              borderRadius: "13px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div>
                  {allButtonsSections?.length > 0 && (
                    <>
                      <div>
                        <div style={{ marginBlock: "50px" }}>
                          <p
                            style={{
                              fontSize: "24px",
                              fontWeight: "900",
                              color: "var(--blueLight)",
                              textAlign: "center",
                            }}
                          >
                            {t("packs.health.detailed_pack.text")}
                          </p>
                        </div>
                        <div className="scrollable-div"
                        >
                          {allButtonsSections?.map((item, index) => {
                            return (
                              <div className="div_homeTurism" key={index}>
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={3}
                                  lg={2}
                                  style={{ paddingInline: "10px" }}
                                >
                                  <button
                                    style={
                                      selectedButtonTravel === item?.id
                                        ? {
                                          backgroundColor: "var(--blueLight)",
                                          color: "white",
                                          border: "1px solid #D9D9D9",
                                          borderRadius: "6px",
                                          marginBottom: "5px",
                                          padding: '10px'
                                        }
                                        : {
                                          backgroundColor: "white",
                                          color: "var(--blueLight)",
                                          border: "1px solid #D9D9D9",
                                          borderRadius: "6px",
                                          marginBottom: "5px",
                                          padding: '10px'
                                        }
                                    }
                                    onClick={() => {
                                      setSelectedButtonTravel(item?.id);
                                    }}
                                  >
                                    {item?.text}
                                  </button>
                                </Grid>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="content-info-selected-health">
                    {selectedButtonTravel === 1 && (
                      <>
                        <div>
                          <p
                            style={{
                              color: "var(--blueLight)",
                              fontSize: "14px",
                              fontWeight: "900",
                            }}
                          >
                            {t("packs.health.detailed_pack.flight_details")}
                          </p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <ul
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              width: "50%",
                            }}
                          >
                            <li className="data-list">
                              <IconPlane />
                              <p>
                                {t("packs.health.detailed_pack.flight_type")}:{" "}
                                {packageMedic?.flight_reserves[0]
                                  ?.flight_type === "one_way"
                                  ? "Solo ida"
                                  : "Ida y regreso"}
                              </p>
                            </li>
                            <li className="data-list">
                              <SvgIcon
                                color={"var(--blueLight)"}
                                name={iconList.IconPinMap2}
                                size={20}
                              />
                              <p>
                                {t("packs.health.detailed_pack.city_departure")}:{" "}
                                {packageMedic?.flight_reserves[0]?.city_dep}
                              </p>
                            </li>
                            <li className="data-list">
                              <SvgIcon
                                color={"var(--blueLight)"}
                                name={iconList.IconPinMap2}
                                size={20}
                              />
                              <p>
                                {t("packs.health.detailed_pack.room_type")}:{" "}
                                {packageMedic?.flight_reserves[0]?.city_arr}
                              </p>
                            </li>
                          </ul>
                          <ul style={{ width: "50%" }}>
                            <li className="data-list">
                              <IconPlane />
                              <p>
                                {t("packs.health.detailed_pack.airline")}:{" "}
                                {packageMedic?.flight_reserves[0]?.code_airline}
                              </p>
                            </li>
                            {packageMedic?.flight_reserves[0]?.flight_type ===
                              "one_way" ? (
                              <li className="data-list">
                                <IconCalendar />
                                <p>
                                  {t("packs.health.detailed_pack.date_departure")}:{" "}
                                  {packageMedic?.flight_reserves[0]?.departure}
                                </p>
                              </li>
                            ) : (
                              <>
                                <li className="data-list">
                                  <IconCalendar />
                                  <p>
                                    {t("packs.health.detailed_pack.date_departure")}:{" "}
                                    {
                                      packageMedic?.flight_reserves[0]
                                        ?.departure
                                    }
                                  </p>
                                </li>
                                <li className="data-list">
                                  <IconCalendar />
                                  <p>
                                  {t("packs.health.detailed_pack.return_date")}:{" "}
                                    {packageMedic?.flight_reserves[0]?.arrival}
                                  </p>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                    {selectedButtonTravel === 2 && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.hotel_details")}
                        </p>
                        <div style={{ display: "flex" }}>
                          <ul style={{ width: "50%" }}>
                            <li className="data-list">
                              <IconStructure />
                              <p>
                                {t("packs.health.detailed_pack.hotel_name")}:{" "}
                                {packageMedic?.hotel_reserves[0]?.hotel_name}
                              </p>
                            </li>
                            <li className="data-list">
                              <SvgIcon
                                color={"var(--blueLight)"}
                                name={iconList.IconPinMap2}
                                size={20}
                              />
                              <p>
                                {t("packs.health.detailed_pack.room_type")}:{" "}
                                {packageMedic?.hotel_reserves[0]?.type_room}
                              </p>
                            </li>
                          </ul>
                          <ul style={{ width: "50%" }}>
                            <li className="data-list">
                              <IconCalendar />
                              <p>
                                {t("packs.health.detailed_pack.date_arrival")}:{" "}
                                {
                                  packageMedic?.hotel_reserves[0]
                                    ?.departure_hotel
                                }
                              </p>
                            </li>
                            <li className="data-list">
                              <IconCalendar />
                              <p>
                                {t("packs.health.detailed_pack.departure_date")}:{" "}
                                {packageMedic?.hotel_reserves[0]?.arrival_hotel}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                    {selectedButtonTravel === 3 && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.transfer_details")}
                        </p>
                        <div>
                          <ul>
                            <ul>
                              {packageMedic?.traslade_reserves?.map((reserve, index) => (
                                <li key={index} className="data-list">
                                  <IconCar />
                                  <p>{t("packs.health.detailed_pack.transfer_type")}: {serviceTypeMap[reserve?.service_type] || reserve?.service_type}</p>
                                </li>
                              ))}
                            </ul>
                          </ul>
                        </div>
                      </>
                    )}
                    {selectedButtonTravel === 4 && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.tour_details")}
                        </p>
                        <div>
                          <ul style={{ width: "50%" }}>
                            <li className="data-list">
                              <IconStructure />
                              <p>
                                {t("packs.health.detailed_pack.tour_name")}:{" "}
                                {packageMedic?.tour_reserves[0]?.name_tour}
                              </p>
                            </li>
                          </ul>
                          <div style={{ marginTop: "30px" }}>
                            <p
                              style={{
                                color: "var(--blueLight)",
                                fontSize: "14px",
                                fontWeight: "900",
                              }}
                            >
                              {t("packs.health.detailed_pack.description")}
                            </p>
                            <p style={{ marginTop: "30px" }}>
                              {packageMedic?.tour_reserves[0]?.description_tour}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedButtonTravel === 5 && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.medical_assistance_details")}
                        </p>
                        <div>
                          <ul style={{ width: "50%" }}>
                            <li className="data-list">
                              <IconStructure />
                              <p>
                                {t("packs.health.detailed_pack.medical_assistance_name")}:{" "}
                                {
                                  packageMedic?.assistance_reserves[0]
                                    ?.name_assistance
                                }
                              </p>
                            </li>
                          </ul>
                          <div style={{ marginTop: "30px" }}>
                            <p
                              style={{
                                color: "var(--blueLight)",
                                fontSize: "14px",
                                fontWeight: "900",
                              }}
                            >
                              {t("packs.health.detailed_pack.medical_assistance_observation")}
                            </p>
                            <p style={{ marginTop: "30px" }}>
                              {
                                packageMedic?.assistance_reserves[0]
                                  ?.observations_assistance
                              }
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {allButtons?.length > 0 && (
                <section className="three-section-detail-health">
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: "900",
                      color: "var(--blueLight)",
                      textAlign: "center",
                      marginBlock: "20px",
                    }}
                  >
                    {t("packs.health.detailed_pack.content_table")}
                  </p>
                  <div
                   className="scrollable-div"
                  >
                    {allButtons?.map((item, index) => {
                      return (
                        <div className="div_homeTurism" key={index}>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={3}
                            lg={2}
                            style={{ paddingInline: "10px" }}
                          >
                            <button
                              style={
                                selectedButton === item?.id
                                  ? {
                                    backgroundColor: "var(--blueLight)",
                                    color: "white",
                                    border: "1px solid #D9D9D9",
                                    borderRadius: "6px",
                                    marginBottom: "5px",
                                    padding: '10px'
                                  }
                                  : {
                                    backgroundColor: "white",
                                    color: "var(--blueLight)",
                                    border: "1px solid #D9D9D9",
                                    borderRadius: "6px",
                                    marginBottom: "5px",
                                    padding: '10px'
                                  }
                              }
                              onClick={() => {
                                setSelectedButton(item?.id);
                              }}
                            >
                              {item?.text}
                            </button>
                          </Grid>
                        </div>
                      );
                    })}
                  </div>
                  <div className="content-info-selected-health">
                    {selectedButton === 1 && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.tabs.packages_advantages")}
                        </p>
                        <div
                          style={{ marginBlock: "10px", fontSize: "12px" }}
                          dangerouslySetInnerHTML={{
                            __html: packageMedic?.advantages,
                          }}
                        ></div>
                      </>
                    )}
                    {selectedButton === 2 && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.tabs.package_description")}
                        </p>
                        <div
                          style={{ marginBlock: "10px", fontSize: "12px" }}
                          dangerouslySetInnerHTML={{
                            __html: packageMedic?.description,
                          }}
                        ></div>
                      </>
                    )}
                    {selectedButton === 3 && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.tabs.package_inclusions")}
                        </p>
                        <div
                          style={{ marginBlock: "10px", fontSize: "12px" }}
                          dangerouslySetInnerHTML={{
                            __html: packageMedic?.inclusions,
                          }}
                        ></div>
                      </>
                    )}
                    {selectedButton === 4 && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.tabs.package_exclusions")}
                        </p>
                        <div
                          style={{ marginBlock: "10px", fontSize: "12px" }}
                          dangerouslySetInnerHTML={{
                            __html: packageMedic?.exclusions,
                          }}
                        ></div>
                      </>
                    )}
                    {selectedButton === 5 && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.tabs.why_surgery")}
                        </p>
                        <div
                          style={{ marginBlock: "10px", fontSize: "12px" }}
                          dangerouslySetInnerHTML={{
                            __html: packageMedic?.why_operate,
                          }}
                        ></div>
                      </>
                    )}
                    {selectedButton === 6 && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.tabs.package_resume")}
                        </p>
                        <div
                          style={{ marginBlock: "10px", fontSize: "12px" }}
                          dangerouslySetInnerHTML={{
                            __html: packageMedic?.extract,
                          }}
                        ></div>
                      </>
                    )}
                    {selectedButton === 7 && packageMedic?.observations && (
                      <>
                        <p
                          style={{
                            color: "var(--blueLight)",
                            fontSize: "14px",
                            fontWeight: "900",
                          }}
                        >
                          {t("packs.health.detailed_pack.tabs.package_observations")}
                        </p>
                        <div
                          style={{ marginBlock: "10px", fontSize: "12px" }}
                          dangerouslySetInnerHTML={{
                            __html: packageMedic?.observations,
                          }}
                        ></div>
                      </>
                    )}
                  </div>
                </section>
              )}

            </div>
          </div>
          <div className="content-form-detail-health">
            <div className="content-icons-share-health">
              <div>
                <MotionConstraints
                  className="slider_container"
                  ref={constraintsRef}
                >
                  <MotionBox
                    className="slider_"
                    drag="x"
                    dragConstraints={constraintsRef}
                  >
                    {allIcons?.map((item, index) => {
                      return (
                        <motion.div className="div_homeTurism" key={index}>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={3}
                            lg={2}
                            className="grid_cardHomeTurism"
                            style={{ cursor: "pointer" }}
                            onClick={
                              item.onClick
                                ? item.onClick
                                : () =>
                                  navigator.clipboard.writeText(item.content)
                            }
                          >
                            {item.content ? (
                              <button
                                type="button"
                                style={{ cursor: "pointer" }}
                              >
                                {item.icon}
                              </button>
                            ) : (
                              <DisabledModal
                                item={{
                                  disable: false,
                                  route: "#",
                                }}
                                active={false}
                              >
                                {item.icon}
                              </DisabledModal>
                            )}
                          </Grid>
                        </motion.div>
                      );
                    })}
                  </MotionBox>
                </MotionConstraints>
              </div>
            </div>
            <div className="btns-detail-health">
              <button style={{
                background: "var(--blueLight)",
              }}
                onClick={handleClickOpenReservations}
              >
                {t("packs.health.detailed_pack.buttons.reserve")}
              </button>

              <button
                onClick={goReservation}
                style={{
                  background: "var(--blueLight)",
                }}
              >
                {t("packs.health.detailed_pack.buttons.quote")}
              </button>
              <button
                onClick={addCart}
                style={{
                  background: "var(--blueLight)",
                }}
              >
                {t("packs.health.detailed_pack.buttons.add_to_cart")}
              </button>
              <DisabledModal
                item={{
                  disable: false,
                  route: "#",
                }}
                active={false}
              >
              </DisabledModal>
            </div>
          </div>
        </section>
      </section>
      <BestExperiences />
      <AnyQuestions title={t("browse_home.have_doubt")} />
      <ModalContainer
        show={showModalPay}
        hiddsetActiveChangeServiceen={setShowModalPay}
        sizeHeight={"fit-content"}
      >
        <FormPayHealth
          pack={packageMedic}
          loading={loading}
          setLoading={setLoading}
          setShowModalPay={setShowModalPay}
        />
      </ModalContainer>
      {loading ? (
        <div style={{ padding: "1rem", width: "100%" }}>
          <Loading />
        </div>
      ) : (
        <></>
      )}


      <Dialog
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "430px!important",
            borderRadius: '8px',
            maxHeight: '95vh' // 70% del alto de la pantalla
          }
        }}
        BackdropProps={{
          sx: {
            backgroundColor: '#021624b0' // Color con opacidad
          }
        }}
        open={openReservations} onClose={handleCloseReservations} maxWidth="md" fullWidth>
        <ReservationForm handleClose={handleCloseReservations} packageMedicData={packageMedic} />
      </Dialog>
    </div>
  );
};

export default DetailPackhealth;
